import React, { useEffect, useState } from 'react';
import {
  SelectButton,
  StyledPaymentPlansTableNewMainWrapper,
  StyledPaymentPlansTableNewPlanFeature,
  StyledPaymentPlansTableNewPlanFeatureCell,
  StyledPaymentPlansTableNewPlanFeaturesWrapper,
  StyledPaymentPlansTableNewPlanTitle,
  StyledPaymentPlansTableNewPlanTitleAndDescriptionWrapper,
  StyledPaymentPlansTableNewPlanTitleAndFeaturesSeparator,
  StyledPaymentPlansTableNewPlanTitlesWrapper,
} from './payment-plans-table-new.styled';
import { SignUpData } from '../../../hooks/use-auth-forms';
import { SubscriptionPlanNew } from '../../super-admin/subscription-plans/interface';
import { font, Text } from '../../../../style-utils/text';
import { Toggle } from '../toggle/toggle';
import { colors } from '../../../../style-utils/colors';
import { useSubscriptionPlans } from './hooks/use-get-payment-plans-data';
import { CheckOnOffCircleIcons } from '../../../icons/check-on-off-circle-icons';

interface PaymentPlansTableProps {
  setSignUpData?: React.Dispatch<React.SetStateAction<SignUpData>>;
  signUpData?: SignUpData;
  setSelectedPlan?: (planId: string, amount: number) => void;
  plans?: SubscriptionPlanNew[];
  isSignUp: boolean;
  defaultSelectedPlanId: string | null;
  // eslint-disable-next-line
    user: any;
  selectedPlanId?: string | null;
}

const PaymentPlansTableNew: React.FC<PaymentPlansTableProps> = ({
  plans: initialPlans,
  signUpData,
  setSelectedPlan,
  setSignUpData,
  user,
}) => {
  const [isMonthly, setIsMonthly] = useState(true);
  const { plans, sortedFeatures } = useSubscriptionPlans({ initialPlans });

  const handleToggle = (toggleState: boolean) => {
    setIsMonthly(toggleState);
  };

  const handlePlanSelect = (
    event: React.MouseEvent<HTMLButtonElement>,
    plan: SubscriptionPlanNew
  ) => {
    event.preventDefault();
    const selectedPrice = isMonthly
      ? parseFloat(plan.price.amount) * 100
      : plan.annual_price
      ? parseFloat(plan.annual_price.amount) * 100
      : parseFloat(plan.price.amount) * 100;

    if (setSignUpData && signUpData) {
      setSignUpData({
        ...signUpData,
        payment_plan_id: plan.id.toString(),
      });
      console.log(`Plan ${plan.id} selected by user.`);
    }
    if (setSelectedPlan) {
      setSelectedPlan(plan.id.toString(), selectedPrice);
      console.log(
        `SelectedPlan function called with id ${plan.id} and amount ${selectedPrice}`
      );
    }
  };

  useEffect(() => {
    if (user?.subscription_plan_id && setSignUpData && signUpData) {
      setSignUpData({
        ...signUpData,
        payment_plan_id: user.subscription_plan_id.toString(),
      });
    }
  }, [user]);

  return (
    <StyledPaymentPlansTableNewMainWrapper>
      <StyledPaymentPlansTableNewPlanTitlesWrapper>
        <StyledPaymentPlansTableNewPlanTitle width="400px" rightBorder={true}>
          <Text
            margin="0"
            fontFamily={font.family}
            fontSize={20}
            fontWeight={700}
            lineHeight="24px"
          >
            Compare our subscription packages
          </Text>
          <Toggle handleToggle={handleToggle} isMonthly={isMonthly} />
        </StyledPaymentPlansTableNewPlanTitle>
        {plans.map((plan, index) => (
          <StyledPaymentPlansTableNewPlanTitle
            key={index}
            rightBorder={true}
            spaceBetween
          >
            <StyledPaymentPlansTableNewPlanTitleAndDescriptionWrapper>
              <Text
                margin="0"
                fontFamily={font.family}
                fontSize={18}
                fontWeight={700}
                color={colors.base_black}
                lineHeight="21.6px"
              >
                {plan.title}
              </Text>
              {/* <Text
              margin="0"
              fontFamily={font.family}
              fontSize={12}
              fontWeight={400}
              color={colors.base_black}
              lineHeight="14.4px"
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </Text> */}
            </StyledPaymentPlansTableNewPlanTitleAndDescriptionWrapper>
            <Text
              margin="0"
              fontFamily={font.family}
              fontSize={32}
              fontWeight={700}
              color={colors.base_black}
              lineHeight="38.4px"
            >
              {plan.price.currency}
              {isMonthly ? plan.price.amount : plan.annual_price.amount}
            </Text>
          </StyledPaymentPlansTableNewPlanTitle>
        ))}
        <StyledPaymentPlansTableNewPlanTitle spaceBetween>
          <StyledPaymentPlansTableNewPlanTitleAndDescriptionWrapper>
            <Text
              margin="0"
              fontFamily={font.family}
              fontSize={18}
              fontWeight={700}
              color={colors.base_black}
              lineHeight="21.6px"
            >
              Custom Premium
            </Text>
            <Text
              margin="0"
              fontFamily={font.family}
              fontSize={12}
              fontWeight={400}
              color={colors.base_black}
              lineHeight="14.4px"
            >
              Contact sales for further information
            </Text>
          </StyledPaymentPlansTableNewPlanTitleAndDescriptionWrapper>
        </StyledPaymentPlansTableNewPlanTitle>
      </StyledPaymentPlansTableNewPlanTitlesWrapper>
      <StyledPaymentPlansTableNewPlanTitleAndFeaturesSeparator />
      <StyledPaymentPlansTableNewPlanFeaturesWrapper>
        {sortedFeatures.map((feature, featureIndex) => (
          <StyledPaymentPlansTableNewPlanFeature key={featureIndex}>
            <StyledPaymentPlansTableNewPlanFeatureCell width="400px">
              <Text
                margin="0"
                fontFamily={font.family}
                fontSize={16}
                fontWeight={400}
                color={colors.base_black}
                lineHeight="18px"
              >
                {feature}
              </Text>
            </StyledPaymentPlansTableNewPlanFeatureCell>
            {plans.map((plan, planIndex) => {
              const featureDetail = plan.features.find(
                (f) => f.name === feature
              );

              return (
                <StyledPaymentPlansTableNewPlanFeatureCell
                  key={`${planIndex}-${feature}`}
                  justifyContent="center"
                >
                  {featureDetail ? (
                    featureDetail.value === '' ? (
                      <CheckOnOffCircleIcons isChecked={true} />
                    ) : featureDetail.value ? (
                      <Text
                        margin="0"
                        fontFamily={font.family}
                        fontSize={14}
                        fontWeight={400}
                        color={colors.base_black}
                        lineHeight="18px"
                      >
                        {featureDetail.value}
                      </Text>
                    ) : (
                      <CheckOnOffCircleIcons isChecked={false} />
                    )
                  ) : (
                    <CheckOnOffCircleIcons isChecked={false} />
                  )}
                </StyledPaymentPlansTableNewPlanFeatureCell>
              );
            })}

            <StyledPaymentPlansTableNewPlanFeatureCell></StyledPaymentPlansTableNewPlanFeatureCell>
          </StyledPaymentPlansTableNewPlanFeature>
        ))}
      </StyledPaymentPlansTableNewPlanFeaturesWrapper>
      <StyledPaymentPlansTableNewPlanFeature>
        <StyledPaymentPlansTableNewPlanFeatureCell width="400px"></StyledPaymentPlansTableNewPlanFeatureCell>
        {plans.map((plan) => (
          <StyledPaymentPlansTableNewPlanFeatureCell
            justifyContent="center"
            key={`${plan.id}-select`}
          >
            <SelectButton
              onClick={(e) => handlePlanSelect(e, plan)}
              selected={
                signUpData?.payment_plan_id.toString() === plan.id.toString()
              }
            >
              {signUpData?.payment_plan_id.toString() === plan.id.toString()
                ? 'Selected Plan'
                : 'Select Plan'}
            </SelectButton>
          </StyledPaymentPlansTableNewPlanFeatureCell>
        ))}
        <StyledPaymentPlansTableNewPlanFeatureCell justifyContent="center">
          <SelectButton selected>Contact sales</SelectButton>
        </StyledPaymentPlansTableNewPlanFeatureCell>
      </StyledPaymentPlansTableNewPlanFeature>
    </StyledPaymentPlansTableNewMainWrapper>
  );
};

export default PaymentPlansTableNew;
